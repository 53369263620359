
// >>> default.less 变量属性修改
@primary-color: #1A68FF;
@primary-color-hover: #1A68FF;
@success-color: #25B856;
@warning-color: #FF8800;
@error-color: #F53B5A;
@text-color: rgba(93, 103, 122, 1);
@text-color-secondary: rgba(144, 150, 163, 1);
@heading-color: rgba(28, 32, 41, 1);
@border-radius-base: 6px;
@height-base: 40px;
@item-hover-bg: #f5f7fA;
@border-color-base: #EBEEF5; // base border outline a component
@disabled-color: rgba(188, 193, 204, 1);
@box-shadow-base: 0 3px 6px -4px rgba(28, 32, 41, 0.12), 0 6px 16px 0 rgba(28, 32, 41, 0.08), 0 9px 28px 8px rgba(28, 32, 41, 0.04);
@btn-shadow: none;
@btn-primary-shadow: none;
@btn-text-shadow: none;
@checkbox-border-width: 1.5px;
@checkbox-border-radius: 3px;
@radio-border-width: 1.5px;
@select-selection-item-bg: #F5F7FA;
@anchor-link-top: 7px;
@tooltip-arrow-width: 5px;
@popover-arrow-width: 6px;
@modal-header-close-size: 56px;
@modal-footer-border-color-split: transparent;
@tabs-card-head-background: #F5F7FA;
@tabs-hover-color: #1A68FF;
@alert-success-border-color: transparent;
@alert-info-border-color: transparent;
@alert-warning-border-color: transparent;
@alert-error-border-color: transparent;
@font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

// >>> checkbox样式修改
.ant-checkbox {
  &-inner {
    border: @checkbox-border-width @border-style-base #DDE1EB;
    border-radius: 2px;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      border-radius: 1.5px;
    }
    &.ant-checkbox-disabled .ant-checkbox-inner::after {
      background-color: #F5F7FA;
      border-color: #DDE1EB;
    }
  }
  
}

// >>> modal样式修改
.ant-modal {
  &-content {
    border-radius: 12px;
  }
  &-header {
    border-radius: 12px 12px 0 0;
  }
  &-footer {
    padding: 16px 16px 40px;
    text-align: center;
    border-radius: 0 0 12px 12px;
  }
}
.ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  .ant-modal-confirm-title {
    text-align: center;
  }
  .ant-modal-confirm-content {
    margin-top: 16px;
  }
  > .anticon {
    margin-right: 0;
    margin-bottom: 16px;
    font-size: 42px;
  }
  + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0;
  }
  .ant-modal-confirm-btns {
    text-align: center;
  }
}

// >>> radio样式修改
.ant-radio {
  &-inner {
    border-color: #DDE1EB;
  }
}
.ant-radio-disabled {
  .ant-radio-inner {
    background-color: #F5F7FA;
    border-color: #DDE1EB !important;
  }
}
.ant-radio-checked.ant-radio-disabled {
  .ant-radio-inner {
    background-color: #FFF;
  }
}
.ant-radio-button-wrapper {
  &-disabled {
    color: #DDE1EB;
    background-color: #F5F7FA;
    &:first-child,
    &:hover {
      color: #DDE1EB;
      background-color: #F5F7FA;
    }
  }
}

// >>> table样式修改
.ant-table {
  &-ping-left {
    &:not(.ant-table-has-fix-left) .ant-table-container {
      &::before {
        box-shadow: inset 10px 0 8px -8px darken(@shadow-color, 5%);
      }
    }
    .ant-table-cell-fix-left-last::after {
      box-shadow: inset 10px 0 8px -8px darken(@shadow-color, 5%);
    }
  }
  &-ping-right {
    &:not(.ant-table-has-fix-right) .ant-table-container {
      &::after {
        box-shadow: inset -10px 0 8px -8px darken(@shadow-color, 5%);
      }
    }

    .ant-table-cell-fix-right-first::after,
    .ant-table-cell-fix-right-last::after {
      box-shadow: inset -10px 0 8px -8px darken(@shadow-color, 5%);
    }
  }
}

// >>> tabs样式修改
.ant-tabs {
  &-ink-bar {
    border-radius: 1.5px;
  }
  &-top,
  &-bottom {
    > .ant-tabs-nav,
    > div > .ant-tabs-nav {
      .ant-tabs-ink-bar {
        height: 3px;
      }
    }
  }
}

// >>> form样式修改
.ant-form-item-label>label:after {
  content: "";
}
